<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body">
        <div class="form-group">
          <h5 for="">الدعوة لواجهة الموقع</h5>
          <input
            type="text"
            class="form-control text-center"
            :value="'https://smart-whats.com/?r=' + user.reseller_code"
            readonly
          />
          <small id="helpId" class="form-text text-muted"
            >ليتمكن الزائر من تصفح الموقع اولاً.</small
          >
        </div>
        <hr />
        <div class="form-group">
          <h5 for="">التسجيل مباشرة في الموقع</h5>
          <input
            type="text"
            class="form-control text-center"
            :value="
              'https://app.smart-whats.com/register#' + user.reseller_code
            "
            readonly
          />
          <small id="helpId" class="form-text text-muted"
            >الذهاب لصفحة التسجيل مباشرة بدون تصفح واجهة الموقع.</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {},
};
</script>

<style>
</style>